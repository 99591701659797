const QUERIES = {
  USERS_LIST: 'users-list',
  ASSETS_LIST: 'assets-list',
  GENPT_ALL_TESTS: 'genpt-all-tests',
  GENPT_ALL_FINDINGS: 'genpt-all-findings',
  GENPT_ALL_ASSETS: 'genpt-all-assets',
  GENPT_CHAT_ASSETS: 'genpt_chat_assets'
}

export {QUERIES}
