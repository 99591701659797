/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useAuth} from '../core/Auth'
import axios from 'axios'
import { googleAuth, verifyToken } from '../core/_requests'

export function GoogleAuth() {
  const [loading, setLoading] = useState(false);
  const {saveAuth, setCurrentUser} = useAuth();

  useEffect(() => {
    const getIdTokenFromUrl = () => {
      const hash = window.location.hash.substr(1);
      const params = new URLSearchParams(hash);
      return params.get('id_token');
    };

    const authenticate = async () => {
      const idToken = getIdTokenFromUrl();

      if (idToken) {
        setLoading(true);
        try {
          const response = await googleAuth(idToken);
        //   console.log("auth", response.data);
          saveAuth(response.data)
          setCurrentUser(response.data)
        } catch (error) {
          console.error('Error during authentication:', error);
        } finally {
          setLoading(false);
        }
      }
    };

    authenticate();
  }, []);



  return (
    <>
        <style>
        {`
          @keyframes rotate {
            from {
              transform: rotate(0deg);
            }
            to {
              transform: rotate(360deg);
            }
          }

          .rotating {
            animation: rotate 1s linear infinite;
          }

          .w-custom{
            width:32px;
            height:auto;
          }
        `}
      </style>
    <div className='text-center'>
        <div className='d-flex align-items-center justify-content-center'>
        <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
              className='h-32px mb-8 mt-4 mx-4'
            />
        <p className='text-gray-700 fw-semibold fs-3 pe-4'>Verifying details...</p>
        </div>
        <img className='rotating w-custom' src={toAbsoluteUrl('/media/svg/misc/loader.svg')} alt='loader' />
    </div>
    </>
  )
}
