import {Suspense} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AuthInit} from './modules/auth'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import AlertProvider from '../_metronic/partials/layout/AlertPopup'

const App = () => {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <Suspense fallback={<LayoutSplashScreen />}>
        <I18nProvider>
          <LayoutProvider>
            <AlertProvider>
              <AuthInit>
                <Outlet />
                <MasterInit />
              </AuthInit>
            </AlertProvider>
          </LayoutProvider>
        </I18nProvider>
      </Suspense>
    </QueryClientProvider>

  )
}

export {App}
