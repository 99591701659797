/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from 'react'
import { Outlet, Route, Routes } from 'react-router-dom'
import { Registration } from './components/Registration'
import { ForgotPassword } from './components/ForgotPassword'
import { Login } from './components/Login'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { GoogleAuth } from './components/GoogleAuth'
import { useThemeMode } from '../../../_metronic/partials'
import { ThirdPartyAuth } from './components/ThirdPartyAuth'

const AuthLayout = () => {
  useEffect(() => {
    document.body.classList.add('bg-body')
    return () => {
      document.body.classList.remove('bg-body')
    }
  }, [])

  ///media/auth/auth-side-image.png

  const { mode } = useThemeMode();

  return (
    <div
      className='d-flex flex-column flex-column-fluid bgi-no-repeat bgi-size-cover '
      style={{
        backgroundImage: `url(${toAbsoluteUrl('/media/auth/auth-side-image.svg')})`,
      }}
    >
      {/* begin::Content */}
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
        {/* begin::Logo */}
        <div className='mb-8'>
          <img alt='Logo' src={toAbsoluteUrl('/media/logos/siemba_genpt.svg')} className='h-48px' />
          <div className='text-center mt-8 fs-5 fw-bold text-primary'>Your Cybersecurity Ally</div>
        </div>
        {/* end::Logo */}
        {/* begin::Wrapper */}
        <div className='w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto'>
          <Outlet />
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
      {/* begin::Footer */}
      {/* <div className='d-flex flex-center flex-column-auto p-10'>
        <div className='d-flex align-items-center fw-bold fs-6'>
          <a href='#' className='text-muted text-hover-primary px-2'>
            About
          </a>

          <a href='#' className='text-muted text-hover-primary px-2'>
            Contact
          </a>

          <a href='#' className='text-muted text-hover-primary px-2'>
            Contact Us
          </a>
        </div>
      </div> */}
      {/* end::Footer */}
    </div>


  )
}

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='/googleauth' element={<GoogleAuth />} />
      {/* <Route path='/thirdpartyauth' element={<ThirdPartyAuth />} /> */}
      <Route path='login' element={<Login />} />
      <Route path='registration' element={<Registration />} />
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
)

export { AuthPage }
