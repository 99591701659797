/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {KTIcon, toAbsoluteUrl} from '../../helpers'
import { resendCode } from '../../../app/modules/auth/core/_requests'
import { useNavigate } from 'react-router-dom'
import { VerifiedUserAnimation } from '../../../app/common/VerifiedUserAnimation'

export type Props = {
  show: boolean
  handleClose: () => void
}

const UserVerifiedModal: React.FC<any> = () => {

const navigate = useNavigate();

  
  const handleSubmit = () => {
    navigate('/login')
  };


  return (
    <>
    <Modal
      className='modal-sticky modal-sticky-lg modal-sticky-bottom-right'
      id='kt_inbox_compose'
      role='dialog'
      data-backdrop='false'
      aria-hidden='true'
      tabIndex='-1'
      show={true}
      animation={false}
    >
        <div className='modal-content'>
        <div className='modal-body text-center'>
            <VerifiedUserAnimation/>
          <p className='form-label fs-2 fw-bold py-4' >Your account is <span className='text-primary'>Verified!</span></p>
          <button type='button' className={`btn btn-primary`} onClick={handleSubmit} >
             Let's Go!
            </button>

      </div>
      </div>
    </Modal>
    </>
  )
}

export {UserVerifiedModal}
