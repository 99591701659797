/* eslint-disable react/jsx-no-target-blank */
import {KTIcon} from '../../../helpers'

const SidebarFooter = () => {
  return (
  <div className='app-sidebar-footer flex-column-auto pt-2 pb-6 px-6' id='kt_app_sidebar_footer'>
  <a
    href="https://www.siemba.io/"
    target='_blank'
    className='btn btn-flex flex-center btn-custom overflow-hidden px-0 h-40px w-100 fs-7 text-gray-600'
  >
    <span className='btn-label'>2024 © Siemba</span>
    <span className='btn-icon fs-7 m-0'>2024 ©</span>
  </a>
</div>
  )
}

export {SidebarFooter}
