/* ENV VARIABLES */
export const API_URL = process.env.REACT_APP_BASE_API_ENDPOINT;
export const THEME_API_URL = process.env.REACT_APP_THEME_API_URL
export const GOOGLE_AUTH_URL = process.env.REACT_APP_GOOGLE_AUTH_URL
export const APP_VERSION = process.env.REACT_APP_VERSION
export const LAYOUT_CONFIG_KEY = process.env.REACT_APP_BASE_LAYOUT_CONFIG_KEY || 'LayoutConfig'
export const GENPT_ENGINE_URL = process.env.REACT_APP_GENPT_URL
// export const ASSET_MS_URL = process.env.REACT_APP_ASSET_MS_URL

// file keys
export const GENPT_BULK_TEST_TEMPLATE = process.env.REACT_APP_GENPT_BULK_TEST_TEMPLATE