import axios from 'axios'
import { API_URL } from '../../../../../app/hooks/utils/constants/env';
// import {AuthModel, UserModel} from './_models'

export const GET_MENU = `${API_URL}/users/menu`;

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'

export function getMenu() {
  const localUserString = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);

  if (!localUserString) {
    console.log("No user data found in localStorage.");
    return Promise.reject(new Error("No user data found in localStorage."));
  }

  const localUser = JSON.parse(localUserString);
  if (!localUser || !localUser.token) {
    console.log("JWT token not found.");
    return Promise.reject(new Error("JWT token not found."));
  }

  return axios.get(GET_MENU, { headers: { Authorization: `Bearer ${localUser.token}` } });
}

