import React, { ReactNode, createContext, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Lottie from 'react-lottie';
import successAnimation from '../../assets/lottie/sucess.json';
import errorAnimation from '../../assets/lottie/error_2.json';

// Define the interface for the alert options
export interface AlertOptions {
    title: string;
    variant: 'success' | 'error';
    description: string;
    onClose: () => void;
    okayText?: string;
    cancelText?: string;
}

// Create a context with a tuple for popup and close functions
export const AlertContext = createContext<[({ title, variant, description, onClose, okayText, cancelText }: AlertOptions) => void, () => void]>([
    () => {},
    () => {}
]);

// Define the interface for the AlertProvider props
interface AlertProviderProps {
    children: ReactNode;
}

// AlertProvider component to provide alert context to its children
const AlertProvider: React.FC<AlertProviderProps> = ({ children }) => {
    const [alertProps, setAlertProps] = useState<AlertOptions | null>(null); // State to store alert properties

    // Function to show the alert with given options
    const popup = ({ title, variant, description, onClose, okayText, cancelText }: AlertOptions) => {
        setAlertProps({ title, variant, description, onClose, okayText, cancelText });
    };

    // Function to handle alert close
    const handleClose = () => {
        if (alertProps && alertProps.onClose) {
            alertProps.onClose();
        }
        setAlertProps(null); // Clear alert state
    };

    // Function to get the appropriate animation based on variant
    const getAnimation = (variant: 'success' | 'error') => {
        const defaultOptions = {
            loop: false,
            autoplay: true,
            animationData: variant === 'success' ? successAnimation : errorAnimation,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };

        return (
            <Lottie options={defaultOptions} height={variant === 'success' ? 100 : 60} width={variant === 'success' ? 100 : 60}/>
        );
    };

    return (
        <AlertContext.Provider value={[popup, handleClose]}>
            {children} {/* Render children components */}
            {alertProps && (
                <Modal show={true} onHide={handleClose} backdrop="static" keyboard={false} centered>
                    {/* Show animation based on variant */}
                    <div className="d-flex align-items-center justify-content-center mb-1 mt-4">
                        {getAnimation(alertProps.variant)}
                    </div>
                    {/* <Modal.Header className='py-4' closeButton >
                        <Modal.Title>{alertProps.title}</Modal.Title>
                    </Modal.Header> */}
                    <Modal.Body className='py-0'>
                        <p className="text-center">{alertProps.description}</p> {/* Alert description */}
                    </Modal.Body>
                    {(alertProps.cancelText || alertProps.okayText) && 
                    <Modal.Footer className='p-3'>
                        {alertProps.cancelText && <Button variant="secondary" onClick={handleClose}>{alertProps.cancelText}</Button>}
                        {alertProps.okayText && <button className='btn btn-sm btn-primary py-2 px-4 rounded' onClick={handleClose}>{alertProps.okayText || 'Okay'}</button>}
                    </Modal.Footer>
                    }
                </Modal>
            )}
        </AlertContext.Provider>
    );
};

export default AlertProvider;
