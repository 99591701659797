/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {KTIcon, toAbsoluteUrl} from '../../helpers'
import { resendCode } from '../../../app/modules/auth/core/_requests'

export type Props = {
  show: boolean
  handleClose: () => void
}

const LoginVerificationModal: React.FC<any> = ({ email, onSubmit, onClose, verificationError }) => {
  const composeToRef = useRef<HTMLInputElement | null>(null)
  const formRef = useRef<HTMLFormElement | null>(null)
  const [composeCC, setComposeCC] = useState('')
  const [composeBCC, setComposeBCC] = useState('')
  const [subject, setSubject] = useState('')
  const [isLoading, setIsLoading] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [timer, setTimer] = useState(60); // Initial timer value in seconds
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const [sentAgain, setSentAgain] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [verificationInputError, setVerificationInputError] = useState('');
  
  const handleSubmit = () => {
    setLoading(true)
    onSubmit(verificationCode);
    setVerificationCode('');
  };

  const resendCodeSubmit = async () => {
    setIsLoading(true)
    setIsResendDisabled(true);
    
    const response = await resendCode(email);
    if (response.status === 201) {
      setTimer(60);
    }
    setIsLoading(false);
    setSentAgain(true);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (timer > 0) {
        setTimer(timer - 1); 
      } else {
        setIsResendDisabled(false); 
        clearInterval(interval); // Stop the timer interval
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [timer]);

  const validateCode = (code: string) => {
    if (/\s/.test(code)) {
      setVerificationInputError('Code should not contain spaces');
      setIsSubmitDisabled(true);
    } else if (code.length !== 6) {
      setVerificationInputError('Code should be exactly 6 digits long');
      setIsSubmitDisabled(true);
    } else if (!/^\d{6}$/.test(code)) {
      setVerificationInputError('Code should contain only numbers');
      setIsSubmitDisabled(true);
    } else {
      setVerificationInputError('');
      setIsSubmitDisabled(false);
    }
  };

  useEffect(() => {
    validateCode(verificationCode);
  }, [verificationCode]);

  return (
    <>
    <style>
    {`
      @keyframes rotate {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }

      .rotating {
        animation: rotate 1s linear infinite;
      }

      .w-custom{
        width:32px;
        height:auto;
      }
    `}
  </style>
    <Modal
      className='modal-sticky modal-sticky-lg modal-sticky-bottom-right'
      id='kt_inbox_compose'
      role='dialog'
      data-backdrop='false'
      aria-hidden='true'
      tabIndex='-1'
      show={true}
      animation={false}
    >
        <div className='modal-content'>
        <div className='modal-header'>
          <h5 className='modal-title text-capitalize'>Welcome to Siemba!</h5>

          <div
            className='btn btn-icon btn-sm btn-active-light-primary ms-2'
            onClick={onClose}
          >
            <KTIcon iconName='cross' className='fs-2x' />
          </div>
        </div>
        <div className='modal-body'>
          <p className='form-label fs-5 fw-semibold pb-4' >To get started, we need to verify your account. We have sent a verification code to your email at <span className='link-primary'>{email}</span>.</p>
          <p className='form-label fs-5 fw-semibold pb-4' >Please enter the Verification Code below to continue.</p>
        <input
          type="password"
          value={verificationCode}
          className='form-control form-control-sm form-control-solid'
          onChange={(e) => setVerificationCode(e.target.value)}
          placeholder="Verification Code"
        />
        {verificationInputError.length > 0 && verificationCode.length > 0 &&  <div className='mt-1 text-danger fw-bold'>{verificationInputError}</div>}
        {verificationError.length > 0 && <div className='mt-1 text-danger fw-bold'>{verificationError}</div>}
        <div className='pt-1 d-flex justify-content-between align-items-center'>
        <div className='fw-bold text-gray-700'>Resend in {timer}s</div>
        {!isLoading &&
          <button type='button' className={`btn btn-link ${isResendDisabled ? 'text-gray-500' : 'text-primary'}`} onClick={resendCodeSubmit} disabled={isResendDisabled}>
              Resend Code
            </button>
        }
        {isLoading && 
          <img width="6px" className='rotating w-custom p-1' src={toAbsoluteUrl('/media/svg/misc/loader.svg')} alt='loader' />
        }
            
        </div>
        {sentAgain && 
        <div className='pt-1 text-gray-500 fw-bold'>
          Code sent again!
        </div>}
        
        </div>
        <div className='modal-footer'>      
        <button id='submit' type='button' className='btn btn-primary' onClick={handleSubmit} disabled={isSubmitDisabled}>
            Verify
          </button>
        </div>
      </div>
    </Modal>
    </>
  )
}

export {LoginVerificationModal}
