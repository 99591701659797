/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from 'react'
import {useIntl} from 'react-intl'
import {KTIcon} from '../../../../helpers'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
import { getMenu } from '../core/_requests'
import { APP_VERSION } from '../../../../../app/hooks/utils/constants/env'

// let sidebarData = [
//   {
//     to: "/dashboard",
//     icon: "element-11",
//     title: "MENU.DASHBOARD",
//   },
//   {
//     icon: "bi bi-box-fill",
//     title: "Assets",
//     //fontIcon: "bi-archive",
//     subItems: [      {
//       to: "/assets/insights",
//       title: "Insights",
//       hasBullet: true,
//     },
//     {
//       to: "/assets",
//       title: "List Assets",
//       hasBullet: true
//     }
//     ]
//   },
//   {
//     to: "/pentests",
//     icon: "bi bi-shield-shaded",
//     title: "Vulnerabilities",
//     fontIcon: "bi-shield-shaded",
//     subItems: [
//       {
//         to: "/assets/insights",
//         title: "Insights",
//         hasBullet: true
//       },
//       {
//         to: "/apps/user-management/users",
//         title: "Assessments",
//         hasBullet: true
//       }
//     ]
//   },
//   {
//     to: "/pentests",
//     icon: "bi bi-tropical-storm",
//     title: "Pentests",
//     fontIcon: "bi-archive",
//     subItems: [
//       {
//         to: "/assets/insights",
//         title: "Insights",
//         hasBullet: true
//       },
//       {
//         to: "/crafted/widgets/tables",
//         title: "Findings",
//         hasBullet: true
//       },
//       {
//         to: "/crafted/widgets/statistics",
//         title: "Blockers",
//         hasBullet: true
//       }
//     ]
//   },
//   {
//     to: "/apps/chat/group-chat",
//     icon: "bi bi-bullseye",
//     title: "GenPT",
//     fontIcon: "bi-chat-left",
//     subItems: []
//   },
//   {
//     to: "/pentests",
//     icon: "bi bi-bug-fill",
//     title: "Attack Surface",
//     fontIcon: "bi-archive",
//     subItems: [
//       {
//         to: "/assets/insights",
//         title: "Insights",
//         hasBullet: true
//       },
//       {
//         to: "/crafted/pages/profile/campaigns",
//         title: "Dynamic Analysis",
//         hasBullet: true
//       }
//     ]
//   },
//   {
//     to: "/pentests",
//     icon: "bi bi-eye-fill",
//     title: "Reconnaissance",
//     fontIcon: "bi-archive",
//     subItems: [
//       {
//         to: "/assets/insights",
//         title: "Insights",
//         hasBullet: true
//       },
//       {
//         to: "/crafted/pages/profile/campaigns",
//         title: "Enumeration",
//         hasBullet: true
//       }
//     ]
//   },
//   // {
//   //   to: "/crafted/pages/profile/overview",
//   //   icon: "message-text-2",
//   //   title: "Integrations",
//   //   fontIcon: "bi-chat-left",
//   //   subItems: []
//   // },
//   {
//     to: "/apps/editor/react-draft",
//     icon: "bi bi-file-earmark-break-fill",
//     title: "Reports",
//     fontIcon: "bi-chat-left",
//     subItems: [
//       {
//         to: "/crafted/pages/profile/documents",
//         title: "Pentest Reports",
//         hasBullet: true
//       },
//       {
//         to: "/crafted/pages/profile/documents",
//         title: "Compliance Reports",
//         hasBullet: true
//       },
//       {
//         to: "/crafted/pages/profile/documents",
//         title: "Custom Reports",
//         hasBullet: true
//       }
//     ]
//   },
//   {
//     to: "/pentests",
//     icon: "bi bi-person-badge-fill",
//     title: "Admin",
//     fontIcon: "bi-archive",
//     subItems: [
//       {
//         to: "/assets",
//         title: "Asset Management",
//         hasBullet: true
//       },
//       {
//         to: "/apps/user-management/users",
//         title: "User Management",
//         hasBullet: true
//       },
//       {
//         to: "/admin/hierarchy",
//         title: "Hierarchy",
//         hasBullet: true
//       },
//       {
//         to: "/crafted/pages/profile/projects",
//         title: "Integrations",
//         hasBullet: true
//       }
//     ]
//   }
// ];


const renderMenuItem = (item:any) => {
  if (item.subItems && item.subItems.length > 0) {
    return (
      <SidebarMenuItemWithSub
      key={item.id}
        to={item.url}
        icon={item.icon}
        title={item.name}
        fontIcon={item.fonticon}
      >
        {item.subItems.map((subItem:any) => renderMenuItem(subItem))}
      </SidebarMenuItemWithSub>
    );
  }

  return (
    <SidebarMenuItem
    key={item.id}
      to={item.url}
      icon={item.icon}
      title={item.name}
      fontIcon={item.fonticon}
      hasBullet={item.parent_menu_id ? true : false}
    />
  );
};




const SidebarMenuMain =  () => {
const intl = useIntl();

const [sidebarData, setSidebarData] = useState<any>([]);

useEffect(() => {
  const fetchData = async () => {
    try {
      const {data} = await getMenu();
      setSidebarData(data);
    } catch (error) {
      console.error('Error fetching sidebar data', error);
    }
  };

  fetchData();
}, []);

return(
  <div>
  {sidebarData.map((item:any) => renderMenuItem(item))}
        <SidebarMenuItemWithSub
      to='/references'
      title='Extras (for Reference)'
      fontIcon='bi-archive'
      icon='element-plus'
      >
      <SidebarMenuItem to='/builder' icon='switch' title='Layout Builder' fontIcon='bi-layers' />
      <SidebarMenuItemWithSub
        to='/crafted/pages'
        title='Pages'
        fontIcon='bi-archive'
        icon='element-plus'
      >
        <SidebarMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <SidebarMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
          <SidebarMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
          <SidebarMenuItem
            to='/crafted/pages/profile/campaigns'
            title='Campaigns'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/documents'
            title='Documents'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          />
        </SidebarMenuItemWithSub>

        <SidebarMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <SidebarMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
          />
          <SidebarMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </SidebarMenuItemWithSub>
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/crafted/accounts'
        title='Accounts'
        icon='profile-circle'
        fontIcon='bi-person'
      >
        <SidebarMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
        <SidebarMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub to='/error' title='Errors' fontIcon='bi-sticky' icon='cross-circle'>
        <SidebarMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <SidebarMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/crafted/widgets'
        title='Widgets'
        icon='element-7'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div>
      <SidebarMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        fontIcon='bi-chat-left'
        icon='message-text-2'
      >
        <SidebarMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
        {/* <SidebarMenuItem to='/apps/chat/siemba-chat' title='GenPT' hasBullet={true} /> */}
      </SidebarMenuItemWithSub>

      <SidebarMenuItem to='/apps/editor/ck-editor' title='CKEditor' icon='element-7' />
      <SidebarMenuItemWithSub
        to='/apps/editor'
        title='Editors'
        fontIcon='bi-chat-left'
        icon='message-text-2'
      >
        <SidebarMenuItem to='/apps/editor/ck-editor' title='CK Editor' hasBullet={true} />
        <SidebarMenuItem to='/apps/editor/react-draft' title='React Draft Editor' hasBullet={true} />
        <SidebarMenuItem to='/apps/editor/tinymce' title='TinyMCE Editor' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItem
        to='/apps/user-management/users'
        icon='abstract-28'
        title='User management'
        fontIcon='bi-layers'
      />
      </SidebarMenuItemWithSub>
</div>
)

//   return (
//     <>
      
//       <SidebarMenuItem
//         to='/dashboard'
//         icon='element-11'
//         title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
//         fontIcon='bi-app-indicator'
//       />
//       <SidebarMenuItemWithSub
//         to='/pentests'
//         title='Onboarding'
//         fontIcon='bi-archive'
//         icon='element-7'
//       >
//           <SidebarMenuItem to='/assets' title='Assets' hasBullet={true} />
//           <SidebarMenuItem to='/crafted/widgets/lists' title='PreRequisites' hasBullet={true} />
//       </SidebarMenuItemWithSub>
      
//       <SidebarMenuItem
//         to='/crafted/widgets/lists'
//         icon='element-11'
//         title='Attack Surface Mapping'
//         fontIcon='bi-app-indicator'
//       />
//       <SidebarMenuItem
//         to='/apps/user-management/users'
//         icon='element-11'
//         title='Recon & Enum'
//         fontIcon='bi-app-indicator'
//       />
//       <SidebarMenuItemWithSub
//         to='/pentests'
//         title='Assesments'
//         fontIcon='bi-archive'
//         icon='element-7'
//       >
//           <SidebarMenuItem to='/crafted/widgets/lists' title='Insights' hasBullet={true} />
//           <SidebarMenuItem to='/apps/user-management/users' title='Vulnerabilities' hasBullet={true} />
//       </SidebarMenuItemWithSub>
//       <SidebarMenuItemWithSub
//         to='/pentests'
//         title='Pentest'
//         fontIcon='bi-archive'
//         icon='element-7'
//       >
//           <SidebarMenuItem to='/crafted/widgets/lists' title='Insights' hasBullet={true} />
//           <SidebarMenuItem to='/apps/user-management/users' title='Findings' hasBullet={true} />
//           <SidebarMenuItem to='/apps/user-management/users' title='Blockers' hasBullet={true} />
//       </SidebarMenuItemWithSub>
//       <SidebarMenuItem to='/apps/chat/siemba-chat' title='GenPT' fontIcon='bi-chat-left'
//         icon='message-text-2' />
//         <SidebarMenuItem to='/crafted/pages/profile/overview' title='Integrations' fontIcon='bi-chat-left'
//         icon='message-text-2' />

// <SidebarMenuItemWithSub
//         to='/pentests'
//         title='Admin'
//         fontIcon='bi-archive'
//         icon='element-7'
//       >
//           <SidebarMenuItem to='/crafted/widgets/lists' title='User Management' hasBullet={true} />
//           <SidebarMenuItem to='/apps/user-management/users' title='Hierarchy' hasBullet={true} />
//       </SidebarMenuItemWithSub>
//       <SidebarMenuItem to='/apps/editor/react-draft' title='Reports' fontIcon='bi-chat-left'
//         icon='message-text-2' />









      // <SidebarMenuItemWithSub
      // to='/references'
      // title='Extras'
      // fontIcon='bi-archive'
      // icon='element-plus'
      // >
      // <SidebarMenuItem to='/builder' icon='switch' title='Layout Builder' fontIcon='bi-layers' />
      // <SidebarMenuItemWithSub
      //   to='/crafted/pages'
      //   title='Pages'
      //   fontIcon='bi-archive'
      //   icon='element-plus'
      // >
      //   <SidebarMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
      //     <SidebarMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
      //     <SidebarMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
      //     <SidebarMenuItem
      //       to='/crafted/pages/profile/campaigns'
      //       title='Campaigns'
      //       hasBullet={true}
      //     />
      //     <SidebarMenuItem
      //       to='/crafted/pages/profile/documents'
      //       title='Documents'
      //       hasBullet={true}
      //     />
      //     <SidebarMenuItem
      //       to='/crafted/pages/profile/connections'
      //       title='Connections'
      //       hasBullet={true}
      //     />
      //   </SidebarMenuItemWithSub>

      //   <SidebarMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
      //     <SidebarMenuItem
      //       to='/crafted/pages/wizards/horizontal'
      //       title='Horizontal'
      //       hasBullet={true}
      //     />
      //     <SidebarMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
      //   </SidebarMenuItemWithSub>
      // </SidebarMenuItemWithSub>
      // <SidebarMenuItemWithSub
      //   to='/crafted/accounts'
      //   title='Accounts'
      //   icon='profile-circle'
      //   fontIcon='bi-person'
      // >
      //   <SidebarMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
      //   <SidebarMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
      // </SidebarMenuItemWithSub>
      // <SidebarMenuItemWithSub to='/error' title='Errors' fontIcon='bi-sticky' icon='cross-circle'>
      //   <SidebarMenuItem to='/error/404' title='Error 404' hasBullet={true} />
      //   <SidebarMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      // </SidebarMenuItemWithSub>
      // <SidebarMenuItemWithSub
      //   to='/crafted/widgets'
      //   title='Widgets'
      //   icon='element-7'
      //   fontIcon='bi-layers'
      // >
      //   <SidebarMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
      //   <SidebarMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
      //   <SidebarMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
      //   <SidebarMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
      //   <SidebarMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
      //   <SidebarMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      // </SidebarMenuItemWithSub>
      // <div className='menu-item'>
      //   <div className='menu-content pt-8 pb-2'>
      //     <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
      //   </div>
      // </div>
      // <SidebarMenuItemWithSub
      //   to='/apps/chat'
      //   title='Chat'
      //   fontIcon='bi-chat-left'
      //   icon='message-text-2'
      // >
      //   <SidebarMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
      //   <SidebarMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
      //   <SidebarMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      //   {/* <SidebarMenuItem to='/apps/chat/siemba-chat' title='GenPT' hasBullet={true} /> */}
      // </SidebarMenuItemWithSub>

      // <SidebarMenuItem to='/apps/editor/ck-editor' title='CKEditor' icon='element-7' />
      // <SidebarMenuItemWithSub
      //   to='/apps/editor'
      //   title='Editors'
      //   fontIcon='bi-chat-left'
      //   icon='message-text-2'
      // >
      //   <SidebarMenuItem to='/apps/editor/ck-editor' title='CK Editor' hasBullet={true} />
      //   <SidebarMenuItem to='/apps/editor/react-draft' title='React Draft Editor' hasBullet={true} />
      //   <SidebarMenuItem to='/apps/editor/tinymce' title='TinyMCE Editor' hasBullet={true} />
      // </SidebarMenuItemWithSub>
      // <SidebarMenuItem
      //   to='/apps/user-management/users'
      //   icon='abstract-28'
      //   title='User management'
      //   fontIcon='bi-layers'
      // />
      // </SidebarMenuItemWithSub>
      
//       {/* <div className='menu-item'>
//         <a
//           target='_blank'
//           className='menu-link'
//           href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
//         >
//           <span className='menu-icon'>
//             <KTIcon iconName='code' className='fs-2' />
//           </span>
          // <span className='menu-title'>Changelog {APP_VERSION}</span>
//         </a>
//       </div> */}
//     </>
//   )
}

export {SidebarMenuMain}
