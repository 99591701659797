import axios from 'axios'
import {AuthModel, UserModel} from './_models'
import { API_URL } from '../../../hooks/utils/constants/env'

export const VERIFY_TOKEN = `${API_URL}/auth/verify-token`
export const EMAIL_CHECK = `${API_URL}/users/emailCheck` //from cognito now
export const LOGIN_URL = `${API_URL}/auth/login`
export const REGISTER_URL = `${API_URL}/auth/register` //from auth -> user-management
export const VERIFY_USER = `${API_URL}/auth/verifyUser` //from auth -> user-management
export const GOOGLE_AUTH = `${API_URL}/users/googleauth` 
export const RESEND_CODE = `${API_URL}/auth/resendCode`

interface EmailCheckResponse {
  emailCheck: boolean;
  message: string
}

export function login(email: string, password: string) {
  return axios.post<any>(LOGIN_URL, {
    email,
    password,
  })
}

export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string,
  companyname: string,
) {
  if(password != password_confirmation){
    console.log("password mismatch")
  }
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
    company_name: companyname,
  })
}

export const emailCheck = async (email: string): Promise<EmailCheckResponse> => {
  // Your Axios call
  const response = await axios.post<EmailCheckResponse>(EMAIL_CHECK, {
    email
  })
  return response.data;
};

export function verifyUser(email:string, verificationCode: string) {
  return axios.post(VERIFY_USER, {
    email,
    verificationCode
  })
}

export function resendCode(email:string) {
  return axios.post(RESEND_CODE, {
    email
  })
}

export function requestPassword(email: string) {
  return axios.post<{result: boolean}>('todo', {
    email,
  })
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(VERIFY_TOKEN, {}, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
}

export function verifyToken(idToken: string) {
  try {
    return axios.post(VERIFY_TOKEN, {}, {
      headers: {
        'Authorization': `Bearer ${idToken}`
      }
    });
  } catch (error) {
    console.error('Error verifying token:', error);
  }
};

export async function googleAuth(idToken: string) {
  try {
    const response = await axios.post(GOOGLE_AUTH, {}, {
      headers: {
        'Authorization': `Bearer ${idToken}`
      }
    });
    return response;
  } catch (error) {
    console.error('Error verifying token:', error);
    throw error;
  }
};
