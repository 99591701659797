import { Animate, AnimateKeyframes, AnimateGroup } from "react-simple-animate";

type Props = {
  className: string
  description: string
  color: string
  img: string
}

const CardsWidget20 = ({className, description, color, img}: Props) => (

  <>
<style>
      {`
        .flip-card {
          perspective: 1000px;
          height: 100%;
        }

        .flip-card-inner {
          position: relative;
          transition: transform 0.3s;
          transform-style: preserve-3d;
          height: 100%;
        }

        .flip-card:hover .flip-card-inner {
          transform: rotateY(180deg);
        }

        .flip-card-front, .flip-card-back {
          position: absolute;
          width: 100%;
          height: 100%;
          backface-visibility: hidden;
          border-radius: .50rem;
          // display:flex;
          // justify-content: space-between;
        }

        .flip-card-back {
          transform: rotateY(180deg);
          color: white;
          
        }
      `}
    </style>

    <div className={`flip-card ${className}`}>
      <div className='flip-card-inner'>
        <div
          className='flip-card-front card bg-primary'
        >
            <div
    className={`card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end bg-primary ${className}`}
  >
    <div className='card-header pt-5'>
      <div className='card-title d-flex flex-column'>

        <span className='fs-2hx fw-bold text-white me-2 lh-1 ls-n2 animated bounce infinite'>
        <AnimateKeyframes
            play
            duration={1.5}
            iterationCount="infinite"
            // keyframes={["scale: 100%", "scale: 120%", "scale: 100%"]}
            keyframes={["opacity: 0","scale: 120%", "opacity: 1", "opacity: 0"]}
          >
          69
          </AnimateKeyframes>
          </span>

        <span className='text-white opacity-75 pt-1 fw-semibold fs-6'>{description}</span>
      </div>
    </div>
    <div className='card-body d-flex align-items-end pt-20 mt-10'>
      <div className='d-flex align-items-center flex-column mt-3 w-100'>
        <div className='d-flex justify-content-between fw-bold fs-6 text-white opacity-75 w-100 mt-auto mb-2'>
          <span>43 Pending</span>
          <span>72%</span>
        </div>

        <div className='h-8px mx-3 w-100 bg-white bg-opacity-50 rounded'>
          <div
            className='bg-white rounded h-8px'
            role='progressbar'
            style={{width: '72%'}}
            aria-valuenow={50}
            aria-valuemin={0}
            aria-valuemax={100}
          ></div>
        </div>
      </div>
    </div>
  </div>
        </div>
      
        <div 
        className='flip-card-back card bg-primary'
        >
          <div className="card-body">
            <h5 className="card-title">More Information</h5>
            <p className="card-text">Additional details here...</p>
          </div>
        </div>
      </div>
    </div>

    
  </>

  

)
export {CardsWidget20}
