/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react';
import Lottie from 'react-lottie';
import animationData from './VerifiedUser.json'; 

const VerifiedUserAnimation: FC = () => {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
      };
    
      return (
        <div className='w-25 m-auto'>
          <Lottie options={defaultOptions} />
        </div>
      );
}

export {VerifiedUserAnimation}
