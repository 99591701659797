/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {emailCheck, register, verifyUser} from '../core/_requests'
import {Link, useNavigate} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'
import {useAuth} from '../core/Auth'
import { VerificationModal } from '../../../../_metronic/partials/layout/VerificationModal';
import debounce from 'lodash/debounce';
import { GOOGLE_AUTH_URL } from '../../../hooks/utils/constants/env'
import { UserVerifiedModal } from '../../../../_metronic/partials/layout/UserVerifiedModal'

const initialValues = {
  firstname: '',
  lastname: '',
  email: '',
  password: '',
  changepassword: '',
  companyname: '',
  acceptTerms: false,

}

const debouncedEmailCheck = debounce(
  (email, resolve, reject) => {
    emailCheck(email)
      .then(response => {
        resolve(!response.emailCheck);
      })
      .catch(error => {
        console.error('Error checking email:', error);
        reject(new Error('Error checking email'));
      });
  },
  300
);

const registrationSchema = Yup.object().shape({
  firstname: Yup.string()
    .matches(/^\S.*$/, 'Field cannot be empty or contain only whitespace')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('First name is required'),
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required')
    .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Wrong email format')
    .test(
      'email-exists',
      'Email already in use. Please try to login',
      (email) => new Promise((resolve, reject) => {
        debouncedEmailCheck(email, resolve, reject);
      })
    ),
  lastname: Yup.string()
    .matches(/^\S.*$/, 'Field cannot be empty or contain only whitespace')
    .max(50, 'Maximum 50 symbols')
    .required('Last name is required'),
  password: Yup.string()
    .min(8, 'Minimum 8 characters')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required')
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\^$*.\[\]{}()?\-"!@#%&/\\,><':;|_~`])[^\s]{8,}$/, 'Password doesnt match the requirement criteria.'),
  changepassword: Yup.string()
    .min(8, 'Minimum 8 characters')
    .max(50, 'Maximum 50 symbols')
    .required('Password confirmation is required')
    .oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
  companyname: Yup.string()
    .nullable()
    .notRequired()
    .matches(/^\s*\S.*$/, 'Field cannot contain only whitespace')
    .max(50, 'Maximum 50 symbols'),
  acceptTerms: Yup.bool().required('You must accept the terms and conditions'),
})

export function Registration() {
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth();
  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const [verificationError, setVerificationError] = useState('')
  const [userVerifiedModal, setUserVerifiedModal] = useState(false);

  const googleAuthUrl = GOOGLE_AUTH_URL
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const {data: auth} = await register(
          values.email,
          values.firstname,
          values.lastname,
          values.password,
          values.changepassword,
          values.companyname
        )
        setShowVerificationModal(true);
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus('The registration details are incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })



  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  const handleVerificationSubmit = async (verificationCode:any) => {
    // Logic to handle verification code submission
    try{
        const response = await verifyUser(
          formik.values.email,
          verificationCode
        )
        
        if(response.status == 400){
          setVerificationError(response.data.message)
        }else{
          setShowVerificationModal(false);
          setUserVerifiedModal(true);
        }

      }
      catch (error:any) {
        console.error("error", error);
        if (error.response && error.response.status === 400) {
          // Handle the "User is not confirmed" error here
          setVerificationError(error.response.data.message)
        }

      }
  };

  return (
<>
<form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      <div className='mb-8'>
        <h1 className='text-primary text-center fw-bolder mb-4'>Sign Up</h1>
      </div>

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {/* begin::Form group Firstname */}
      <div className='d-flex gap-4 justify-content-between'>
      <div className='fv-row mb-4 w-100'>
        <label className='form-label fw-bolder text-dark fs-6'>First Name</label>
        <input
          placeholder='Enter First name'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('firstname')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.firstname && formik.errors.firstname,
            },
            {
              'is-valid': formik.touched.firstname && !formik.errors.firstname,
            }
          )}
        />
        {formik.touched.firstname && formik.errors.firstname && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.firstname}</span>
            </div>
          </div>
        )}
      </div>
      <div className='fv-row mb-4 w-100'>
        <label className='form-label fw-bolder text-dark fs-6'>Last Name</label>
        <input
          placeholder='Enter Last name'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('lastname')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.lastname && formik.errors.lastname,
            },
            {
              'is-valid': formik.touched.lastname && !formik.errors.lastname,
            }
          )}
        />
        {formik.touched.lastname && formik.errors.lastname && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.lastname}</span>
            </div>
          </div>
        )}
      </div>
      </div>
      {/* end::Form group */}


      {/* begin::Form group Email */}
      <div className='fv-row mb-4'>
        <label className='form-label fw-bolder text-dark fs-6'>Official Email</label>
        <input
          placeholder='Enter Official Email'
          type='email'
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
              <span role='alert'>{formik.touched.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Password */}
      <div className='fv-row mb-4' data-kt-password-meter='true'>
        <div className='mb-1'>
          <label className='form-label fw-bolder text-dark fs-6'>Password</label>
          <div className='position-relative mb-3'>
            <input
              type='password'
              placeholder='Enter Password'
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
          {/* begin::Meter */}
          <div
            className='d-flex align-items-center mb-3'
            data-kt-password-meter-control='highlight'
          >
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
          </div>
          {/* end::Meter */}
        </div>
        <div className='text-muted'>
          Use 8 or more characters with a mix of letters, numbers, symbols & no spaces.
        </div>
      </div>

      {/* end::Form group */}

      {/* begin::Form group Confirm password */}
      <div className='fv-row mb-4'>
        <label className='form-label fw-bolder text-dark fs-6'>Confirm Password</label>
        <input
          type='password'
          placeholder='Enter Password confirmation'
          autoComplete='off'
          {...formik.getFieldProps('changepassword')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
            },
            {
              'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
            }
          )}
        />
        {formik.touched.changepassword && formik.errors.changepassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.changepassword}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      <div className='fv-row mb-4'>
        <label className='form-label fw-bolder text-dark fs-6'>Company Name</label>
        <input
          placeholder='Enter Company name'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('companyname')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.companyname && formik.errors.companyname,
            },
            {
              'is-valid': formik.touched.companyname && !formik.errors.companyname,
            }
          )}
        />
        {formik.touched.companyname && formik.errors.companyname && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.companyname}</span>
            </div>
          </div>
        )}
      </div>

      {/* begin::Form group */}
      <div className='d-flex mb-8'>
        <label className='form-check form-check-inline' htmlFor='kt_login_toc_agree'>
          <input
            className='form-check-input cursor-pointer'
            type='checkbox'
            id='kt_login_toc_agree'
            {...formik.getFieldProps('acceptTerms')}
          />

        </label>
        <span className='text-gray-700 fw-semibold'>
            By signing up, you agree to our 
            <a
              href='/'
              target='_blank'
              className='ms-1 fw-bold link-primary'
            >
              Terms of Service{' '}
            </a>
            and acknowledge our
            <a
              href='/'
              target='_blank'
              className='ms-1 fw-bold link-primary'
            >
            Privacy Policy
            </a>
            .
          </span>
        {formik.touched.acceptTerms && formik.errors.acceptTerms && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.acceptTerms}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
        >
          {!loading && <span className='indicator-label'>Sign Up</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        {/* <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_signup_form_cancel_button'
            className='btn btn-lg btn-light-primary w-100 mb-5'
          >
            Cancel
          </button>
        </Link> */}
      </div>
      <div className='text-gray-500 fw-semibold fs-6'>
       Already a Member?{' '}
        <Link to='/auth/login' className='link-primary'>
          Sign In
        </Link>
      </div>
      <div className='text-gray-500 text-center fw-bold fs-6 mt-10 pt-10'>
       Need help?{' '}
        <a href='mailto:support@siemba.com' className='link-primary'>
          Click here
        </a>
      </div>
      {/* end::Form group */}
    </form>
    {showVerificationModal && (
      <VerificationModal 
        first_name={formik.values.firstname}
        last_name={formik.values.lastname}
        email={formik.values.email}
        onSubmit={handleVerificationSubmit} 
        onClose={() => {
          setShowVerificationModal(false)
          setVerificationError('')
          setLoading(false)
        }}
        verificationError={verificationError}
      />
    )}

    {userVerifiedModal && 
    <UserVerifiedModal/>
    }
</>
  )
}
